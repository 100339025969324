<template>
  <v-card elevation="1" class="mb-10">
    <v-form ref="form" class="pt-4">
      <v-row class="px-6 pt-2">
        <v-col cols="6">
          <v-text-field
            v-model="forcaDeVenda.name"
            outlined
            dense
            label="Nome"
            :readonly="true"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="forcaDeVenda.code"
            outlined
            dense
            label="Código"
            :readonly="true"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: "ForcaDeVendaFormulario",
  props: {
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    forcaDeVenda: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    headers: [
      { text: "Matricula", value: "registration" },
      { text: "Nome", value: "name" },
      { text: "Tipo", value: "user.type" },
      { text: "Ações", value: "id", sortable: false, width: '10%' },
    ],
  }),
  methods: {
    gotTo(id) {
      this.$router.push({ name: "FuncionarioEditar", params: { id: id } });
    },
  },
  computed: {
    pageText() {
      return "Total de " + this.forcaDeVenda.employees.length;
    },
  },
};
</script>
<style lang="scss">
.tabela-forca-de-venda {
    border: 1px solid #ccc
}
.tabela-forca-de-venda tbody tr:nth-of-type(even) {
  background-color: #ffffff;
}

.tabela-forca-de-venda tbody tr:nth-of-type(odd) {
  background-color: #eeeeee;
}
</style>
