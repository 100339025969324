var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"elevation":"0"}},[_c('div',{staticClass:"d-flex justify-space-between mb-2"},[_c('v-btn',{staticClass:"mb-2",attrs:{"depressed":"","color":"fmq_gray","dark":"","small":"","outlined":""},on:{"click":_vm.openModal}},[_vm._v("Selecionar Produtos")]),_c('v-btn',{staticClass:"mb-2",attrs:{"depressed":"","color":"fmq_gray","dark":"","small":"","loading":_vm.loadingBtn,"disabled":_vm.products.length === 0},on:{"click":_vm.associar}},[_vm._v("Associar Produtos")])],1),_c('div',[_c('v-data-table',{staticClass:"tabela-forca-de-venda",attrs:{"headers":_vm.headers,"items":_vm.products,"items-per-page":10,"locale":"pt-BR","no-data-text":"Nenhum dado encontrado","footer-props":{
        'items-per-page-text': 'Itens por página:',
        'items-per-page-all-text': 'Todos',
        'items-per-page-options': [20, 50, 100, -1],
        'show-first-last-page': true,
        'show-current-page': true,
        'page-text': _vm.pageTextProducts,
      }},scopedSlots:_vm._u([{key:`item.id`,fn:function({ item }){return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","depressed":"","color":"fmq_gray","dark":""},on:{"click":function($event){return _vm.gotTo(item.id)}}},[_vm._v("Detalhes")]),_c('v-btn',{attrs:{"small":"","depressed":"","color":"red","dark":""},on:{"click":function($event){return _vm.removerProduto(item)}}},[_vm._v("Remover")])],1)]}}],null,true)}),_c('TableModal',{attrs:{"dialog":_vm.dialogModelo,"dialogMessage":"Lista de Produtos"},on:{"close":function($event){_vm.dialogModelo = false}}},[_c('ProdutosTabelaModal',{attrs:{"headers":_vm.headersProdutos,"produtos":_vm.produtos,"produtosAssociados":_vm.products,"total":_vm.totalProdutos,"page":_vm.formData.page,"pageText":_vm.pageText,"loading":_vm.loading},on:{"handleFilter":_vm.filter,"associarProduto":_vm.associarProduto},scopedSlots:_vm._u([{key:"type",fn:function(){return [_c('v-autocomplete',{attrs:{"items":['FQM', 'Concorrente'],"hide-no-data":"","hide-selected":"","item-text":"label","item-value":"id","placeholder":"Busque pelo tipo","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.buscar(_vm.formData);
              _vm.formData.page = 1;}},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}})]},proxy:true},{key:"name",fn:function(){return [_c('v-text-field',{attrs:{"placeholder":"Busque pela marca","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.buscar(_vm.formData);
              _vm.formData.page = 1;}},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]},proxy:true},{key:`line.name`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listLines,"hide-no-data":"","hide-selected":"","item-text":"label","item-value":"id","placeholder":"Busque pela linha","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.buscar(_vm.formData);
              _vm.formData.page = 1;}},model:{value:(_vm.formData.linesId),callback:function ($$v) {_vm.$set(_vm.formData, "linesId", $$v)},expression:"formData.linesId"}})]},proxy:true},{key:`laboratory.name`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listLaboratories,"hide-no-data":"","hide-selected":"","item-text":"label","item-value":"id","placeholder":"Busque pelo laboratório","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.buscar(_vm.formData);
              _vm.formData.page = 1;}},model:{value:(_vm.formData.laboratoriesId),callback:function ($$v) {_vm.$set(_vm.formData, "laboratoriesId", $$v)},expression:"formData.laboratoriesId"}})]},proxy:true},{key:"composition",fn:function(){return [_c('v-text-field',{attrs:{"placeholder":"Busque pela composição","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.buscar(_vm.formData);
              _vm.formData.page = 1;}},model:{value:(_vm.formData.composition),callback:function ($$v) {_vm.$set(_vm.formData, "composition", $$v)},expression:"formData.composition"}})]},proxy:true},{key:`classification.name`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listClassifications,"hide-no-data":"","hide-selected":"","item-text":"label","item-value":"id","placeholder":"Busque pelo classificação","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.buscar(_vm.formData);
              _vm.formData.page = 1;}},model:{value:(_vm.formData.classificationsId),callback:function ($$v) {_vm.$set(_vm.formData, "classificationsId", $$v)},expression:"formData.classificationsId"}})]},proxy:true},{key:"presentations",fn:function(){return [_c('v-text-field',{attrs:{"placeholder":"Busque pela apresentação","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.buscar(_vm.formData);
              _vm.formData.page = 1;}},model:{value:(_vm.formData.presentations),callback:function ($$v) {_vm.$set(_vm.formData, "presentations", $$v)},expression:"formData.presentations"}})]},proxy:true},{key:`salesForces`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listSalesFroces,"hide-no-data":"","hide-selected":"","item-text":"label","item-value":"id","placeholder":"Busque pelo força de venda","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.buscar(_vm.formData);
              _vm.formData.page = 1;}},model:{value:(_vm.formData.salesForcesId),callback:function ($$v) {_vm.$set(_vm.formData, "salesForcesId", $$v)},expression:"formData.salesForcesId"}})]},proxy:true},{key:`specialties`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listSpecialties,"hide-no-data":"","hide-selected":"","item-text":"label","item-value":"id","placeholder":"Busque pelo força de venda","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.buscar(_vm.formData);
              _vm.formData.page = 1;}},model:{value:(_vm.formData.specialtiesId),callback:function ($$v) {_vm.$set(_vm.formData, "specialtiesId", $$v)},expression:"formData.specialtiesId"}})]},proxy:true},{key:"id",fn:function(){return [_c('div',{staticClass:"d-flex justify-start"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","x-small":"","fab":"","color":"red"},on:{"click":_vm.clear}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"outlined":"","x-small":"","fab":"","color":"blue"},on:{"click":_vm.search}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)]},proxy:true}],null,true)})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }