<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <v-progress-circular
      v-if="loading"
      :size="100"
      :width="4"
      color="fmq_black"
      indeterminate
      class="d-flex mx-auto py-8 mt-8"
    ></v-progress-circular>
    <div v-else>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn
            small
            depressed
            color="fmq_gray"
            dark
            outlined
            to="/forca-de-vendas"
          >
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
        </div>

        <ForcaDeVendaFormulario :forcaDeVenda="forcaDeVenda" />

        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header> Colaboradores </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ForcaDeVendasColaboradores :forcaDeVenda="forcaDeVenda" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="pt-6">
          <v-expansion-panel>
            <v-expansion-panel-header>
              Produtos Associados
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ForcaDeVendasAssociados
                :forcaDeVenda="forcaDeVenda"
                :loadingBtn="loadingBtn"
                @associar="associar"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Produtos associados com sucesso"
      @close="
        success = false;
        getShowSaleForce();
      "
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import ForcaDeVendaFormulario from "@/components/forca-de-venda/ForcaDeVendaFormulario";
import ForcaDeVendasColaboradores from "@/components/forca-de-venda/ForcaDeVendasColaboradores";
import ForcaDeVendasAssociados from "@/components/forca-de-venda/ForcaDeVendasAssociados";
import { showSaleForce, updateProductsSaleForce } from "@/services/sale-force";
export default {
  name: "ForcaDeVendasExibir",
  components: {
    ForcaDeVendaFormulario,
    ForcaDeVendasColaboradores,
    ForcaDeVendasAssociados,
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Força de Venda",
        disabled: false,
        to: "/forca-de-vendas",
      },
      {
        text: "Exibir Força de Venda",
        disabled: true,
        to: "",
      },
    ],
    forcaDeVenda: [],
    loading: false,
    error: false,
    success: false,
    messageError: null,
    loadingBtn: false,
  }),
  created() {
    this.getShowSaleForce();
  },
  methods: {
    async getShowSaleForce() {
      try {
        this.loading = true;
        const resp = await showSaleForce(this.$route.params.id);
        this.forcaDeVenda = resp.data;
      } catch (e) {
        this.loading = false;
        this.error = true;
        this.messageError = e.response.data.message;
      } finally {
        this.loading = false;
      }
    },
    async associar(event) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await updateProductsSaleForce(this.$route.params.id, {
          productsId: event,
        }).then(() => {
          this.success = true;
        });
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    goTo() {
      this.sucess = false;
      this.$router.push({ name: "ForcaDeVendas" });
    },
  },
};
</script>

<style></style>
