var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"elevation":"0"}},[_c('div',[_c('v-data-table',{staticClass:"tabela-forca-de-venda",attrs:{"headers":_vm.headers,"items":_vm.forcaDeVenda.employees,"items-per-page":10,"locale":"pt-BR","no-data-text":"Nenhum dado encontrado","footer-props":{
        'items-per-page-text': 'Itens por página:',
        'items-per-page-all-text': 'Todos',
        'items-per-page-options': [20, 50, 100, -1],
        'show-first-last-page': true,
        'show-current-page': true,
        'page-text': _vm.pageText,
      }},scopedSlots:_vm._u([{key:`item.id`,fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","depressed":"","color":"fmq_gray","dark":""},on:{"click":function($event){return _vm.gotTo(item.id)}}},[_vm._v("Detalhes")])]}}],null,true)})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }