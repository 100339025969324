<template>
  <v-card elevation="0">
    <div class="d-flex justify-space-between mb-2">
      <v-btn
        class="mb-2"
        depressed
        color="fmq_gray"
        dark
        @click="openModal"
        small
        outlined
        >Selecionar Produtos</v-btn
      >
      <v-btn
        class="mb-2"
        depressed
        color="fmq_gray"
        dark
        @click="associar"
        small
        :loading="loadingBtn"
        :disabled="products.length === 0"
        >Associar Produtos</v-btn
      >
    </div>
    <div>
      <v-data-table
        :headers="headers"
        :items="products"
        :items-per-page="10"
        class="tabela-forca-de-venda"
        locale="pt-BR"
        no-data-text="Nenhum dado encontrado"
        :footer-props="{
          'items-per-page-text': 'Itens por página:',
          'items-per-page-all-text': 'Todos',
          'items-per-page-options': [20, 50, 100, -1],
          'show-first-last-page': true,
          'show-current-page': true,
          'page-text': pageTextProducts,
        }"
      >
        <template v-slot:[`item.id`]="{ item }">
          <div class="d-flex">
            <v-btn
              small
              depressed
              color="fmq_gray"
              dark
              @click="gotTo(item.id)"
              class="mr-2"
              >Detalhes</v-btn
            >
            <v-btn
              small
              depressed
              color="red"
              dark
              @click="removerProduto(item)"
              >Remover</v-btn
            >
          </div>
        </template>
      </v-data-table>
      <TableModal
        :dialog="dialogModelo"
        @close="dialogModelo = false"
        dialogMessage="Lista de Produtos"
      >
        <ProdutosTabelaModal
          :headers="headersProdutos"
          :produtos="produtos"
          :produtosAssociados="products"
          :total="totalProdutos"
          :page="formData.page"
          :pageText="pageText"
          :loading="loading"
          @handleFilter="filter"
          @associarProduto="associarProduto"
        >
          <template v-slot:type>
            <v-autocomplete
              v-model="formData.type"
              :items="['FQM', 'Concorrente']"
              hide-no-data
              hide-selected
              item-text="label"
              item-value="id"
              placeholder="Busque pelo tipo"
              solo
              flat
              dense
              :hide-details="true"
              :clearable="true"
              @input="
                buscar(formData);
                formData.page = 1;
              "
            ></v-autocomplete>
          </template>

          <template v-slot:name>
            <v-text-field
              v-model="formData.name"
              placeholder="Busque pela marca"
              solo
              flat
              dense
              :hide-details="true"
              @input="
                buscar(formData);
                formData.page = 1;
              "
              :clearable="true"
            >
            </v-text-field>
          </template>

          <template v-slot:[`line.name`]>
            <v-autocomplete
              v-model="formData.linesId"
              :items="listLines"
              hide-no-data
              hide-selected
              item-text="label"
              item-value="id"
              placeholder="Busque pela linha"
              solo
              flat
              dense
              :hide-details="true"
              :clearable="true"
              @input="
                buscar(formData);
                formData.page = 1;
              "
            ></v-autocomplete>
          </template>

          <template v-slot:[`laboratory.name`]>
            <v-autocomplete
              v-model="formData.laboratoriesId"
              :items="listLaboratories"
              hide-no-data
              hide-selected
              item-text="label"
              item-value="id"
              placeholder="Busque pelo laboratório"
              solo
              flat
              dense
              :hide-details="true"
              :clearable="true"
              @input="
                buscar(formData);
                formData.page = 1;
              "
            ></v-autocomplete>
          </template>

          <template v-slot:composition>
            <v-text-field
              v-model="formData.composition"
              placeholder="Busque pela composição"
              solo
              flat
              dense
              :hide-details="true"
              @input="
                buscar(formData);
                formData.page = 1;
              "
              :clearable="true"
            >
            </v-text-field>
          </template>

          <template v-slot:[`classification.name`]>
            <v-autocomplete
              v-model="formData.classificationsId"
              :items="listClassifications"
              hide-no-data
              hide-selected
              item-text="label"
              item-value="id"
              placeholder="Busque pelo classificação"
              solo
              flat
              dense
              :hide-details="true"
              :clearable="true"
              @input="
                buscar(formData);
                formData.page = 1;
              "
            ></v-autocomplete>
          </template>

          <template v-slot:presentations>
            <v-text-field
              v-model="formData.presentations"
              placeholder="Busque pela apresentação"
              solo
              flat
              dense
              :hide-details="true"
              @input="
                buscar(formData);
                formData.page = 1;
              "
              :clearable="true"
            >
            </v-text-field>
          </template>

          <template v-slot:[`salesForces`]>
            <v-autocomplete
              v-model="formData.salesForcesId"
              :items="listSalesFroces"
              hide-no-data
              hide-selected
              item-text="label"
              item-value="id"
              placeholder="Busque pelo força de venda"
              solo
              flat
              dense
              :hide-details="true"
              :clearable="true"
              @input="
                buscar(formData);
                formData.page = 1;
              "
            ></v-autocomplete>
          </template>

          <template v-slot:[`specialties`]>
            <v-autocomplete
              v-model="formData.specialtiesId"
              :items="listSpecialties"
              hide-no-data
              hide-selected
              item-text="label"
              item-value="id"
              placeholder="Busque pelo força de venda"
              solo
              flat
              dense
              :hide-details="true"
              :clearable="true"
              @input="
                buscar(formData);
                formData.page = 1;
              "
            ></v-autocomplete>
          </template>

          <template v-slot:id>
            <div class="d-flex justify-start">
              <v-btn
                class="mr-2"
                outlined
                x-small
                fab
                color="red"
                @click="clear"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn outlined x-small fab color="blue" @click="search">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </div>
          </template>
        </ProdutosTabelaModal>
      </TableModal>
    </div>
  </v-card>
</template>

<script>
import { searchProduct } from "@/services/product";
import { listLine } from "@/services/line";
import { listLaboratory } from "@/services/laboratory";
import { listClassification } from "@/services/classification.js";
import { listSaleForce } from "@/services/sale-force.js";
import { listSpecialty } from "@/services/specialty.js";
import ProdutosTabelaModal from "@/components/produtos/ProdutosTabelaModal";
export default {
  name: "ForcaDeVendasAssociados",
  components: {
    ProdutosTabelaModal,
  },
  props: {
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    forcaDeVenda: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    headers: [
      { text: "Nome", value: "name" },
      { text: "Linha", value: "line.name" },
      { text: "Laboratório", value: "laboratory.name" },
      { text: "Classificação", value: "classification.name" },
      { text: "Ação", value: "id", width: "10%" },
    ],
    dialogModelo: false,
    formData: {
      sort: null,
      perPage: 20,
      page: 1,
      name: null,
      type: null,
      linesId: null,
      laboratoriesId: null,
      composition: null,
      classificationsId: null,
      presentations: null,
      salesForcesId: null,
      specialtiesId: null,
    },
    produtos: [],
    totalProdutos: null,
    pageText: null,
    loading: false,
    listLines: [],
    listLaboratories: [],
    listClassifications: [],
    listSalesFroces: [],
    listSpecialties: [],
    headersProdutos: [
      { text: "Tipo", value: "type" },
      { text: "Marca", value: "name" },
      { text: "Linha", value: "line.name" },
      { text: "Laboratório", value: "laboratory.name" },
      { text: "Composição", value: "composition" },
      { text: "Classificação", value: "classification.name" },
      { text: "Apresentações", value: "presentations" },
      { text: "Força", value: "salesForces" },
      { text: "Especialidades", value: "specialties" },
      { text: "Ações", value: "id", sortable: false },
    ],
    products: [],
  }),
  created() {
    this.forcaDeVenda.products.forEach((item) => {
      this.products.push(item);
    });
  },
  methods: {
    openModal() {
      this.dialogModelo = true;
      this.buscar(this.formData);
      this.listarLinhas();
      this.listarLaboratorios();
      this.listarClassificacao();
      this.listarForcaDeVemdas();
      this.listarEspecialidades();
    },
    async buscar(payload) {
      this.produtos = [];
      try {
        this.loading = true;
        const resp = await searchProduct(payload);
        this.produtos = resp.data;
        this.totalProdutos = resp.meta.total;
        this.formData.page = resp.meta.currentPage;
        this.pageText = `${resp.meta.from}-${resp.meta.to} de ${resp.meta.total}`;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async listarLinhas() {
      const resp = await listLine();
      this.listLines = resp.data;
    },
    async listarLaboratorios() {
      const resp = await listLaboratory();
      this.listLaboratories = resp.data;
    },
    async listarClassificacao() {
      const resp = await listClassification();
      this.listClassifications = resp.data;
    },
    async listarForcaDeVemdas() {
      const resp = await listSaleForce();
      this.listSalesFroces = resp.data;
    },
    async listarEspecialidades() {
      const resp = await listSpecialty();
      this.listSpecialties = resp.data;
    },
    async associar() {
      const productsId = [];
      this.products.forEach((item) => {
        productsId.push(item.id);
      });
      this.$emit("associar", productsId);
    },
    filter(event) {
      if (event.orderBy) {
        this.formData.sort = event.orderBy + event.orderSorted;
      } else {
        this.formData.sort = null;
      }
      this.formData.perPage = event.perPage;
      this.formData.page = event.page;
      this.buscar(this.formData);
    },
    clear() {
      (this.formData.name = null),
        (this.formData.type = null),
        (this.formData.linesId = null),
        (this.formData.laboratoriesId = null),
        (this.formData.composition = null),
        (this.formData.classificationsId = null),
        (this.formData.presentations = null),
        (this.formData.salesForcesId = null),
        (this.formData.specialtiesId = null),
        (this.formData.page = 1);
      this.buscar(this.formData);
    },
    search() {
      this.formData.page = 1;
      this.buscar(this.formData);
    },
    gotTo(id) {
      const url = this.$router.resolve({
        name: "ProdutoVisualizar",
        params: { id: id },
      });
      window.open(url.href, "_blank");
    },
    associarProduto(event) {
      if (!this.products.some((item) => item.id === event.id)) {
        this.products.push(event);
      }
    },
    removerProduto(item) {
      const index = this.products.indexOf(item);
      if (index > -1 && index < this.products.length) {
        this.products.splice(index, 1);
      }
    },
  },
  computed: {
    pageTextProducts() {
      return "Total de " + this.products.length;
    },
  },
};
</script>
<style lang="scss">
.tabela-forca-de-venda {
  border: 1px solid #ccc;
}
.tabela-forca-de-venda tbody tr:nth-of-type(even) {
  background-color: #ffffff;
}

.tabela-forca-de-venda tbody tr:nth-of-type(odd) {
  background-color: #eeeeee;
}
</style>
