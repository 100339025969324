<template>
  <v-data-table
    :headers="headers"
    :items="produtos"
    :items-per-page="20"
    :page="page"
    class="elevation-1 tabela-produtos"
    locale="pt-BR"
    loading-text="Carregando"
    no-data-text="Nenhum dado encontrado"
    :loading="loading"
    :server-items-length="total"
    @update:options="handleFilter"
    :footer-props="{
      'items-per-page-text': 'Produtos por página:',
      'items-per-page-options': [20, 50, 100],
      'show-first-last-page': true,
      'show-current-page': true,
      'page-text': pageText,
    }"
  >
    <template v-slot:[`body.prepend`]>
      <tr>
        <template v-for="(header, index) in headers">
          <th :key="index">
            <slot :name="header.value"> </slot>
          </th>
        </template>
      </tr>
    </template>

    <template v-slot:[`item.composition`]="{ item }">
      <v-tooltip bottom max-width="480">
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            v-html="truncarString(item.composition)"
          ></div>
        </template>
        <div v-html="item.composition"></div>
      </v-tooltip>
    </template>

    <template v-slot:[`item.presentations`]="{ item }">
      <v-tooltip bottom max-width="480">
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            v-html="truncarString(item.presentations)"
          ></div>
        </template>
        <div v-html="item.presentations"></div>
      </v-tooltip>
    </template>

    <template v-slot:[`item.salesForces`]="{ item }">
      <v-chip
        class="ma-1 teal lighten-4"
        v-for="saleForce in item.salesForces"
        :key="saleForce.id"
        x-small
        >{{ saleForce.name }}</v-chip
      >
    </template>

    <template v-slot:[`item.specialties`]="{ item }">
      <v-chip
        class="ma-1 orange lighten-4"
        v-for="specialty in item.specialties"
        :key="specialty.id"
        x-small
        >{{ specialty.name }}</v-chip
      >
    </template>

    <template v-slot:[`item.id`]="{ item }">
      <v-btn
        v-if="verificarPresencaPorId(produtosAssociados, item.id)"
        small
        depressed
        color="fmq_gray"
        disabled
        >Associado
      </v-btn>
      <v-btn
        v-else
        small
        depressed
        color="fmq_gray"
        dark
        @click="selecionar(item)"
        >{{
          verificarPresencaPorId(produtosSelecionados, item.id)
            ? "Desselecionar"
            : "Selecionar"
        }}
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "ProdutosTabelaModal",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    produtos: {
      type: Array,
      required: true,
    },
    produtosAssociados: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageText: {
      type: String,
      default: "-",
    },
  },
  data: () => ({
    formData: {
      orderBy: null,
      orderSorted: null,
      perPage: null,
      page: null,
    },
    produtosSelecionados: []
  }),
  created() {
    this.formData.page = this.page;
  },
  methods: {
    handleFilter(event) {
      (this.formData.orderBy = event.sortBy[0]),
        (this.formData.orderSorted = event.sortDesc[0] ? ":desc" : ":asc"),
        (this.formData.perPage = event.itemsPerPage),
        (this.formData.page = event.page);
      this.$emit("handleFilter", this.formData);
    },
    gotTo(name, id) {
      this.$router.push({ name: name, params: { id: id } });
    },
    truncarString(str) {
      if (str) {
        if (str.length <= 100) {
          return str;
        } else {
          return str.substring(0, 100) + "...";
        }
      }
    },
    verificarPresencaPorId(arrayDeObjetos, idProcurado) {
      for (let i = 0; i < arrayDeObjetos.length; i++) {
        if (arrayDeObjetos[i].id === idProcurado) {
          return true;
        }
      }
      return false;
    },
    selecionar(produto) {
      if (this.verificarPresencaPorId(this.produtosSelecionados, produto.id)) {
        this.removerObjetoPorId(this.produtosSelecionados, produto.id);
        this.$emit('associarProduto', produto)
      } else {
        this.produtosSelecionados.push(produto);
        this.$emit('associarProduto', produto)
      }
    },
    removerObjetoPorId(arrayDeObjetos, idParaRemover) {
      const indiceParaRemover = arrayDeObjetos.findIndex(
        (objeto) => objeto.id === idParaRemover
      );
      if (indiceParaRemover !== -1) {
        arrayDeObjetos.splice(indiceParaRemover, 1);
      }
    },
  },
};
</script>
<style lang="scss">
.tabela-produtos tbody tr:nth-of-type(even) {
  background-color: #ffffff;
}

.tabela-produtos tbody tr:nth-of-type(odd) {
  background-color: #eeeeee;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
  padding: 0px 4px;
}
</style>
