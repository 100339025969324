var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1 tabela-produtos",attrs:{"headers":_vm.headers,"items":_vm.produtos,"items-per-page":20,"page":_vm.page,"locale":"pt-BR","loading-text":"Carregando","no-data-text":"Nenhum dado encontrado","loading":_vm.loading,"server-items-length":_vm.total,"footer-props":{
    'items-per-page-text': 'Produtos por página:',
    'items-per-page-options': [20, 50, 100],
    'show-first-last-page': true,
    'show-current-page': true,
    'page-text': _vm.pageText,
  }},on:{"update:options":_vm.handleFilter},scopedSlots:_vm._u([{key:`body.prepend`,fn:function(){return [_c('tr',[_vm._l((_vm.headers),function(header,index){return [_c('th',{key:index},[_vm._t(header.value)],2)]})],2)]},proxy:true},{key:`item.composition`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"480"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({domProps:{"innerHTML":_vm._s(_vm.truncarString(item.composition))}},'div',attrs,false),on))]}}],null,true)},[_c('div',{domProps:{"innerHTML":_vm._s(item.composition)}})])]}},{key:`item.presentations`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"480"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({domProps:{"innerHTML":_vm._s(_vm.truncarString(item.presentations))}},'div',attrs,false),on))]}}],null,true)},[_c('div',{domProps:{"innerHTML":_vm._s(item.presentations)}})])]}},{key:`item.salesForces`,fn:function({ item }){return _vm._l((item.salesForces),function(saleForce){return _c('v-chip',{key:saleForce.id,staticClass:"ma-1 teal lighten-4",attrs:{"x-small":""}},[_vm._v(_vm._s(saleForce.name))])})}},{key:`item.specialties`,fn:function({ item }){return _vm._l((item.specialties),function(specialty){return _c('v-chip',{key:specialty.id,staticClass:"ma-1 orange lighten-4",attrs:{"x-small":""}},[_vm._v(_vm._s(specialty.name))])})}},{key:`item.id`,fn:function({ item }){return [(_vm.verificarPresencaPorId(_vm.produtosAssociados, item.id))?_c('v-btn',{attrs:{"small":"","depressed":"","color":"fmq_gray","disabled":""}},[_vm._v("Associado ")]):_c('v-btn',{attrs:{"small":"","depressed":"","color":"fmq_gray","dark":""},on:{"click":function($event){return _vm.selecionar(item)}}},[_vm._v(_vm._s(_vm.verificarPresencaPorId(_vm.produtosSelecionados, item.id) ? "Desselecionar" : "Selecionar")+" ")])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }