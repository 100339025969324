<template>
  <v-card elevation="0">
    <div>
      <v-data-table
        :headers="headers"
        :items="forcaDeVenda.employees"
        :items-per-page="10"
        class="tabela-forca-de-venda"
        locale="pt-BR"
        no-data-text="Nenhum dado encontrado"
        :footer-props="{
          'items-per-page-text': 'Itens por página:',
          'items-per-page-all-text': 'Todos',
          'items-per-page-options': [20, 50, 100, -1],
          'show-first-last-page': true,
          'show-current-page': true,
          'page-text': pageText,
        }"
      >
        <template v-slot:[`item.id`]="{ item }">
          <v-btn small depressed color="fmq_gray" dark @click="gotTo(item.id)"
            >Detalhes</v-btn
          >
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ForcaDeVendasColaboradores",
  props: {
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    forcaDeVenda: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    headers: [
      { text: "Matricula", value: "registration" },
      { text: "Nome", value: "name" },
      { text: "Tipo", value: "user.type" },
      { text: "Ações", value: "id", sortable: false, width: "10%" },
    ],
  }),
  methods: {
    gotTo(id) {
      const url = this.$router.resolve({ name: "FuncionarioEditar", params: { id: id } });
      window.open(url.href, '_blank');
    },
  },
  computed: {
    pageText() {
      return "Total de " + this.forcaDeVenda.employees.length;
    },
  },
};
</script>
<style lang="scss">
.tabela-forca-de-venda {
  border: 1px solid #ccc;
}
.tabela-forca-de-venda tbody tr:nth-of-type(even) {
  background-color: #ffffff;
}

.tabela-forca-de-venda tbody tr:nth-of-type(odd) {
  background-color: #eeeeee;
}
</style>
